import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gaming from "../components/gaming"
import SelectRouter from "./selectRouter"

const GamingPage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Gaming Channels"
        description="youtuber.life brings you top youtube gaming channels"
      />
      <SelectRouter />
      <Gaming />
    </Layout>
  )
}
export default GamingPage
