import React from "react"
import ListItem from "./listItem"
const gaming = [
  {
    name: "Fernanfloo",
    subs: "33.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-C3Kl93mzlX-AWt7g8snIaOp5sAheO3vh64A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCV4xOVpbcV8SdueDCOxLXtQ",
    rank: 1,
  },
  {
    name: "VEGETTA777",
    subs: "23.8M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78s4fWESK46cz-SsxlPTARAOy46PuH78SqfIA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCam8T03EOFBsNdR0thrFHdQ",
    rank: 2,
  },
  {
    name: "VanossGaming",
    subs: "24.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79FzS9jn3sjc41VQPdz-vth-Hc3u1aXwvu12A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKqH_9mk1waLgBiL2vT5b9g",
    rank: 3,
  },
  {
    name: "Markiplier",
    subs: "23.9M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_mJfPTB24ngbdx3aD2H4xsLENbB3Z6J8a05g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC7_YxT-KID8kRbqZo7MyscQ",
    rank: 4,
  },
  {
    name: "jacksepticeye",
    subs: "22.2M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78G71edCaQ7PL59C7XO0KBQt3pFDIjhjDPcvQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYzPXprvl5Y-Sf0g4vX-m6g",
    rank: 5,
  },
  {
    name: "Ninja",
    subs: "22.1M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78y8MCCrVBY1j_wTkMft7fW_4it-VRqWVgYNg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCAW-NpUFkMyCNrvRSSGIvDQ",
    rank: 6,
  },
  {
    name: "DanTDM",
    subs: "21.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_t5ekdUDowWCGVJ5FIGoBO4lI4TFCoC_7vZQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCS5Oz6CHmeoF7vSad0qqXfw",
    rank: 7,
  },
  {
    name: "MrBeast",
    subs: "21.1M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79GuzIhiArE0EfFD8lHukJAj6cw_xcgwtvChA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/MrBeast6000",
    rank: 8,
  },
  {
    name: "AuthenticGames",
    subs: "17.2M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-9L84AzKSIroH33yF0yEwNL2AlhoGghE3itw=s288-mo-c-c0xffffffff-rj-k-no",
    link:
      "https://yt3.ggpht.com/a/AGF-l7-9L84AzKSIroH33yF0yEwNL2AlhoGghE3itw=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 9,
  },
  {
    name: "PopularMMOs",
    subs: "16.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-tOO6ewDvYK9Ao6FPw2eGfExL40Ox-OCS-BA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpGdL9Sn3Q5YWUH2DVUW1Ug",
    rank: 10,
  },
  {
    name: "Ali-A",
    subs: "16.4M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78DdKuBzXax2D1o96mqz_t-wGWKq_ubx9nJ4g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYVinkwSX7szARULgYpvhLw",
    rank: 11,
  },
  {
    name: "TheWillyrex",
    subs: "15.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78tEIIfAT1ehBB04Kha9w7XbzUk5NJJJoDnkA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC4LHNX8d8RqnDX0OezgmCTg",
    rank: 12,
  },
  {
    name: "Willyrex",
    subs: "14.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-a6QBV5n-nx8wDct9jGpv70QGUxn1jfK_TZA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC8rNKrqBxJqL9izOOMxBJtw",
    rank: 13,
  },
  {
    name: "Clash of Clans",
    subs: "14.2M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79f4uImlXFUVddY6kkCp4PEsSjgc_ateCu1vw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCD1Em4q90ZUK2R5HKesszJg",
    rank: 14,
  },
  {
    name: "Jelly",
    subs: "12.8M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78_r47XbisyAmeMyJ1yXw7n1IiF4Ry-AbBvmg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC0DZmkupLYwc0yDsfocLh0A",
    rank: 15,
  },
  {
    name: "IGN",
    subs: "12.1M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_9qSdJiz9DN4LrfrsErtr31Jrvq6I5LyquSg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKy1dAqELo0zrOtPkf0eTMw",
    rank: 16,
  },
  {
    name: "Lachlan",
    subs: "11.9M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-SBTZil762ENHKCtmdMGYEC4bx34jMh2Z1bA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCh7EqOZt7EvO2osuKbIlpGg",
    rank: 17,
  },
  {
    name: "H2ODelirious",
    subs: "11.8M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l790ZYUJkx981t5aqIkCVQ2kDr88Z2jwmllFfw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCClNRixXlagwAd--5MwJKCw",
    rank: 18,
  },
  {
    name: "The Game Theorists",
    subs: "11.7M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-WkqQJlLZPI2hYhMTphqIboUrafOdRygPGuA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCo_IB5145EVNcf8hw1Kku7w",
    rank: 19,
  },
  {
    name: "SSundee",
    subs: "11.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78EZCl72qShmL7Zu9LZL_0J1xZG3_wi8xdb0Q=s288-mo-c-c0xffffffff-rj-k-no",
    link:
      "https://www.youtube.com/channel/https://www.youtube.com/channel/UCke6I9N4KfC968-yRcd5YRg",
    rank: 20,
  },
  {
    name: "iTownGamePlay *Terror&Diversión*",
    subs: "11.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79VAGC6wcBPBd--GrlgBSRD6p8l5Sy0PFtE-g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKmlxQVs0vHtaLldRIQ8lsA",
    rank: 21,
  },
  {
    name: "Sky Does Everything",
    subs: "11.6M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79nibfFZ-ERxkAy8HTok-2YNIXW-lT4hV5S5w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCKlhpmbHGxBE6uw9B_uLeqQ",
    rank: 22,
  },
  {
    name: "FGTeeV",
    subs: "11.4M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78wlCOaohNaJaB_L_aLjmuFk9l8GXA3jLX2XQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCC-RHF_77zQdKcA75hr5oTQ",
    rank: 23,
  },
  {
    name: "TazerCraft",
    subs: "11.1M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_I7r7I-vFJrvSGn7z_aSuWhl6-BRxwkat2_A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCjBO43ykxlSs3j7F7EXcBUQ",
    rank: 24,
  },
  {
    name: "Tfue",
    subs: "10.9M",
    category: "",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_K2YuaDOpVl0Fi292AsEguc0cidoCh0SQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC9lSZSYpDDE18hoFA7YlBpw",
    rank: 25,
  },
]

const Gaming = () => <ListItem data={gaming} />

export default Gaming
